import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app').factory('autoCompleteProviderService', autoCompleteProviderService);

function autoCompleteProviderService(completionService, referenceService, userService) {
	return {
		providers: {
			completion: {
				createProvider(fieldName, completionType) {
					if (completionType === 'asset') {
						return {
							getResults(searchText, cancellationToken) {
								return completionService.getAssetSuggestionsAsync(searchText, cancellationToken);
							},
							map: function map(source) {
								if (!source) {
									return undefined;
								}

								if (Array.isArray(source)) {
									return source.sort((a, b) => a.length - b.length).map(map);
								}

								return {
									id: source.id,
									text: source.id,
									data: source,
								};
							},
						};
					}
					return {
						getResults(searchText, cancellationToken) {
							return completionService.getCompletionSuggestionsAsync(
								fieldName,
								searchText,
								cancellationToken
							);
						},
						map: function map(source) {
							if (!source) {
								return undefined;
							}

							if (Array.isArray(source)) {
								return source.sort((a, b) => a.length - b.length).map(map);
							}

							return {
								id: `completion:${fieldName}:${source}`,
								text: source,
								data: { text: source },
							};
						},
					};
				},
			},
			authors: {
				createProvider() {
					return {
						getResults: userService.searchUsersAsync,
						map: function map(source) {
							if (!source) {
								return undefined;
							}

							if (Array.isArray(source)) {
								return _.map(source, map);
							}

							const data = {
								name: source.alias || source.name,
								title: source.title,
								userId: source.id,
							};

							return {
								id: `author:${data.userId}`,
								text: data.name,
								disambiguation: data.title,
								data: _.pick(data, ['name', 'userId']),
							};
						},
					};
				},
			},
			bibleReference: {
				createProvider() {
					return {
						getResults: referenceService.parseReferenceAsync,
						map: function map(source) {
							if (!source) {
								return undefined;
							}

							if (Array.isArray(source)) {
								return _.map(source, map);
							}

							const data = source.reference
								? source
								: {
										text: source.text,
										reference: {
											value: source.value,
										},
								  };

							return {
								id: `reference:${data.reference.value}`,
								text: source.text,
								data,
							};
						},
					};
				},
			},
			freebase: {
				createProvider() {
					return {
						getResults(searchText, cancellationToken) {
							const options = { limit: 5 };
							return completionService.getFreebaseCompletionSuggestionsAsync(
								searchText,
								options,
								cancellationToken
							);
						},
						map: function map(source) {
							if (!source) {
								return undefined;
							}

							if (Array.isArray(source)) {
								return _.map(source, map);
							}

							return {
								id: `freebase:${source.freebase.mid}`,
								text: source.text,
								disambiguation: source.freebase.notable,
								data: source,
							};
						},
					};
				},
			},
			uav: {
				createProvider() {
					return {
						getResults(searchText, cancellationToken) {
							const options = { limit: 10 };
							return completionService.getUavCompletionSuggestionsAsync(
								searchText,
								options,
								cancellationToken
							);
						},
						map: function map(source) {
							if (!source) {
								return undefined;
							}

							if (Array.isArray(source)) {
								return _.map(source, map);
							}

							return {
								id: `reference:${source.uav.reference}`,
								text: source.text,
								disambiguation: source.uav.kind,
								data: {
									text: source.text,
									reference: {
										value: source.uav.reference,
									},
								},
							};
						},
					};
				},
			},
		},
	};
}
